<template>
    <v-dialog v-model="getDepartmentDialog" persistent :fullscreen="$vuetify.breakpoint.xsOnly" width="30%">
        <v-card>
           
            <v-card-title class="d-flex justify-center">
                Enter Name to Start
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text align="center">
                 <span class="red--text"> {{errorText }}</span>
                <v-text-field label="Name" v-model="userName" :rules="nameRules" autocomplete="off"
                    prepend-icon="mdi-account" @keyup.enter="submitInfo()">
                </v-text-field>
                <!-- <v-btn color="primary" @click="logout()" class="mx-5" outlined>
                        Logout
                    </v-btn> -->
                <v-btn color="primary" @click="submitInfo()" @keyup.enter="submitInfo()">
                    Submit
                </v-btn>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text class="pt-4">
                <span>Please enter your name to get started.
                    You’ll be directed to a page where you can add Questions and Answers
                    to the List.</span>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: "selectDepartment",
    computed: {
        ...mapGetters(['getDepartmentDialog', 'userProfile']),
        getDepartments() {
            console.log('User Profile', this.userProfile)
            if (this.userProfile !== null) {
                return this.userProfile.departments
            }
            else {
                return ['Something went wrong']
            }
        }
    },
    data() {
        return {
            userName: '',
            nameRules: [
                v => !!v || 'Name is required',
            ],
            courtRules: [
                v => !!v || 'Department is required',
            ],
            errorText: ''
        }
    },
    methods: {
        submitInfo() {
            if (this.userName.length > 0) {
                const userData = {
                    name: this.userName,
                }
                this.$store.commit('updateUserInfo', userData)
                this.$store.commit('setDepartmentDialog', false)
                this.$store.dispatch('pullFaq', this.userProfile.name)
            }else{
                this.errorText = 'Name cannot be empty'
            }

        },
        // logout() {
        //     window.sessionStorage.clear();
        //     window.location.reload()
        // }
    }
}
</script>

<style scoped>
</style>
