<template>
  <v-app style="background-color: #f5f5f5 !important;">
    <v-app-bar flat app color="primary" v-if="login">
      <span class="white--text text-lg-h5 text-md-h5 ">
        Frequently Asked Questions
      </span>
      <v-spacer></v-spacer>
      <span class="white--text mr-4 text-h7" v-if="userProfile.userRole === 'Admin'">
        Hello, {{ userProfile.name }}
      </span>
      <span v-else class="white--text mr-4 text-h7">
        Hello {{ getUserInfo.name }}, {{ userProfile.name }}
      </span>
      <v-icon color="white" @click="logout()">mdi-logout</v-icon>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
    <v-footer class="main" v-if="login">
      <v-col cols="11" class="text-center grey--text">
        {{ new Date().getFullYear() }} — <strong> Frequently Asked Questions </strong>
      </v-col>
      <v-col cols="1" align="right" align-self="center">
        <span class="primary--text text-h5 font-weight-bold mt-10"> Help </span>
        <v-icon @click="$store.commit('showHelpDialog', true)" dark large color="primary">
          mdi-help-circle
        </v-icon>
      </v-col>
    </v-footer>
    <login></login>
    <help></help>
    <snackBar></snackBar>
    <add-client></add-client>
    <add-client-admin></add-client-admin>
    <select-department></select-department>
  </v-app>
</template>

<script>
import login from "@/components/login";
import help from '@/components/help';
import snackBar from '@/components/snackBar.vue';
import { mapGetters } from 'vuex';
import AddClient from './components/addClient.vue';
import SelectDepartment from './components/selectDepartment.vue';
import loading from './components/loading.vue'
import AddClientAdmin from './components/addClientAdmin.vue';

export default {
  name: 'App',

  data: () => ({
    showUserInfo: false,
    userName: ''
  }),
  components: {
    login,
    help,
    snackBar,
    AddClient,
    SelectDepartment,
    loading,
    AddClientAdmin
  },
  computed: {
    ...mapGetters(['login', 'userProfile', 'getUserInfo'])
  },
  watch: {
    // getUserInfo(newVal) {
    //   this.showUserInfo = true;
    //   this.userName = newVal.name;
    // }
  },
  beforeCreate() {
    this.$store.dispatch("checkForUser")
  },
  mounted() {
    //fetchProfile
    this.$store.dispatch('fetchProfile', window.sessionStorage.getItem('loginEmail'))
  },
  methods: {
    logout() {
      window.sessionStorage.clear();
      window.location.reload()
      //window.sessionStorage.setItem('loginEmail', null);
    }
  }
};
</script>
<style>
html,
body {
  margin: 0;
  overflow: hidden;
}

body::-webkit-scrollbar {
  display: none;
}

.main {
  background-color: #f5f5f5 !important;
}

.v-card::-webkit-scrollbar {
  -webkit-appearance: none;
  color: #6b1530;
  width: 10px;
}

.v-card::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px #6b1530;
}

.v-data-table__wrapper::-webkit-scrollbar {
  -webkit-appearance: none;
  color: #6b1530;
  width: 10px;
}

.v-data-table__wrapper::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px #6b1530;
}
</style>
