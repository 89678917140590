<template>
    <v-dialog width="35%" persistent v-model="show">
        
        <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" class="mr-2" @click="editUser" color="primary">
                mdi-pencil
            </v-icon>
        </template>
        
        


        <v-card>
            <v-card-title class="justify-center">Update Client</v-card-title>
            <v-divider></v-divider>
            <v-form class="mx-5" align="center">
                <v-row>
                    <v-col cols="6">
                        <v-text-field label="Client Name" v-model="client.name" disabled></v-text-field>
                        <v-text-field label="Client Email" v-model="client.email" disabled></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-row no-gutters>
                            <v-col cols="10">
                                <v-text-field label="Add Department" v-model="department"></v-text-field>
                            </v-col>
                            <v-col cols="2" align-self="center">
                                <v-icon @click="addDepartment(department)">mdi-plus</v-icon>
                            </v-col>
                            <v-col>
                                <v-sheet min-height="10" max-height="150" class="mb-5 overflow-y-auto overflow-x-hidden"
                                    v-if="client.departments.length > 0">
                                    <v-chip v-for="(department, i) in client.departments" :key="i" close class="ma-1"
                                        @click:close="removeDepartment(department)">
                                        {{ department }}
                                    </v-chip>
                                </v-sheet>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <v-btn @click="updateClient()" class="ma-3" width="150" outlined color="primary"> Update Client </v-btn>
                <v-btn @click="show = false" class="ma-3" width="150" color="primary">
                    Cancel </v-btn>
            </v-form>
        </v-card>
    </v-dialog>
    
</template>
<script>
export default {
    name: 'editClient',
    props: {
        clientIndex: Object
    },
    data() {
        return {
            show: false,
            client: {
                name: '',
                email: '',
                departments: [],
                id: null
            },
            department: ''
        }
    },
    methods: {
        editUser() {
            this.client = Object.assign({}, this.clientIndex.data());
            this.client.id = this.clientIndex.id
        },
        updateClient() {
            console.log('Client to be updated: ', this.client)
            this.$store.dispatch("updateClient", this.client)
            this.show = false
        },
        removeDepartment(department) {
            this.client.departments.splice(this.client.departments.indexOf(department), 1)
        },
        addDepartment(department) {
            this.client.departments.push(department)
        }
    }
}
</script>