var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"align-self":"center","align":"center","cols":"12"}},[_c('v-btn',{staticClass:"ma-2",attrs:{"width":"175","height":"50","outlined":""},on:{"click":function($event){return _vm.$router.push('/createUser')}}},[_vm._v(" Add Admin ")]),_c('v-btn',{staticClass:"ma-2",attrs:{"width":"175","height":"50","outlined":""},on:{"click":function($event){return _vm.$store.commit('setClientAdminDialog', true)}}},[_vm._v(" Add Client Admin ")]),_c('v-btn',{staticClass:"ma-2",attrs:{"width":"175","height":"50","outlined":""},on:{"click":function($event){return _vm.$store.commit('setAddClientDialog', true)}}},[_vm._v(" Add Client ")])],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-col',[_c('v-container',[_c('v-card',{staticClass:"d-flex align-center justify-center white--text",attrs:{"color":"primary"}},[_c('v-card-title',[_vm._v("Clients")])],1),_c('div',{staticClass:"scrollable-card"},_vm._l((_vm.getClients),function(client,i){return _c('v-card',{key:i,staticClass:"mt-1 black--text",attrs:{"align-self":"center","cols":"12"}},[_c('v-card-title',{staticClass:"text-subtitle-1"},[_vm._v(_vm._s(client.data().name)+" ")]),_c('v-card-subtitle',{staticClass:"caption"},[_vm._v(_vm._s(client.data().email)+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('edit-client',{attrs:{"clientIndex":client}})],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-3",attrs:{"icon":""},on:{"click":function($event){_vm.pullFaqs(client.data().name)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-eye-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("View FAQ's")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('emailPopup',{attrs:{"clientIndex":client}})],1)]}}],null,true)},[_c('span',[_vm._v("Email Info")])])],1)],1)}),1)],1)],1)],1),_c('v-col',{attrs:{"cols":"10","align":"center"}},[_c('v-card',{attrs:{"width":"85%","height":_vm.height,"flat":""}},[_c('v-card-title',{staticClass:"justify-center"},[_vm._v(" Frequently Asked Questions ")]),_c('v-divider'),(_vm.getFaqs.length !== 0)?_c('v-card-title',{staticClass:"justify-center"},[_vm._v("FAQs Added: "+_vm._s(_vm.getFaqs.length))]):_vm._e(),(_vm.getFaqs.length !== 0)?_c('v-card',{staticClass:"overflow-y-auto ma-5",attrs:{"height":_vm.height - 175,"flat":""}},[_c('v-expansion-panels',{staticClass:"px-6",attrs:{"focusable":"","dark":""}},_vm._l((_vm.getFaqs),function(item,index){return _c('v-expansion-panel',{key:index,staticClass:"mt-2"},[_c('v-expansion-panel-header',{staticClass:"white--text",attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(item.data().question)+" ")]),_c('v-expansion-panel-content',{staticClass:"black--text px-2 pt-2 text-justify",attrs:{"color":"white"}},[_c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',[_vm._v("Answer: "+_vm._s(item.data().answer))]),_c('br'),_c('span',[_vm._v("Department: "+_vm._s(item.data().department))]),_c('br'),_c('span',[_vm._v(" User: "+_vm._s(item.data().user))])])],1)],1)],1)}),1)],1):(!_vm.select)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mt-10",attrs:{"cols":"12","align-self":"center","align":"center"}},[_c('span',{staticClass:"text-h6"},[_vm._v(" Please Select a Court to see added FAQS")])])],1):_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mt-10",attrs:{"cols":"12","align-self":"center","align":"center"}},[_c('span',{staticClass:"text-h6"},[_vm._v(" No Question Added ")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }