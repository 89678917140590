<template>
  <v-row no-gutters>
    <v-col cols="12" align="center" align-self="center">
      <v-card width="85%" class="pa-5">
        <v-row no-gutters>
          <v-col>
            <v-form ref="qna" lazy-validation>
              <v-row>
                <v-col cols="3">
                  <v-select
                    @focus="
                      ($event) => {
                        $event.target.click();
                      }
                    "
                    v-model="faqPair.department"
                    ref="depart"
                    :items="getDepartments"
                    :rules="courtRules"
                    label="Select Department"
                    autocomplete="off"
                    required
                    :error-messages="errorMessages"
                    outlined
                  ></v-select>
                </v-col>
                <v-col cols="3">
                  <v-textarea
                    outlined
                    auto-grow
                    rows="1"
                    row-height="25"
                    label="Questions"
                    autocomplete="off"
                    v-model="faqPair.question"
                    :rules="textRules"
                  ></v-textarea>
                </v-col>
                <v-col cols="3">
                  <v-textarea
                    outlined
                    auto-grow
                    rows="1"
                    row-height="25"
                    label="Answers"
                    autocomplete="off"
                    v-model="faqPair.answer"
                    :rules="textRules"
                  ></v-textarea>
                </v-col>
                <v-col cols="3" >
                  <input
                    type="file"
                    ref="file"
                    style="display: none"
                    accept=".png,.pdf"
                    @change="handleFileChange"
                    width="150px"
                  />
                  <v-btn
                    color="primary"
                    @click="$refs.file.click()"
                    outlined
                    width="380px"
                    height="57px"
                    
                  >
                    <v-icon left>mdi-paperclip</v-icon>
                    {{ attachment }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col align="right">
                  <v-btn
                    width="125"
                    outlined
                    color="primary"
                    class="mx-5"
                    @click="resetform"
                  >
                    <v-icon left>mdi-refresh</v-icon> Reset
                  </v-btn>
                  <v-btn width="125" color="primary" @click="addFaq()">
                    <v-icon left>mdi-arrow-right-thick</v-icon> Submit
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="12" align="center" justify="center">
      <v-card class="px-5" width="85%" elevation="0">
        <v-card-actions>
          <v-switch
            dense
            hide-details
            v-model="showMyResponse"
            label="Only show my responses."
          ></v-switch>
        </v-card-actions>
        <v-data-table
          
          :headers="addedQnaHeaders"
          :items="qnaList"
          :items-per-page="7"
          :search="search"
        >
          <template v-slot:item.question="{ item }">
            {{ item.data().question | trimLength }}
          </template>
          <template v-slot:item.answer="{ item }">
            {{ item.data().answer | trimLength }}
          </template>
          <template v-slot:item.department="{ item }">
            {{ item.data().department | trimLength }}
          </template>

          <template v-slot:item.user="{ item }">
            {{ item.data().user }}
          </template>
          <template v-slot:item.dateTime="{ item }">
            {{ item.data().dateTime | formatDate }}
          </template>
          <template v-slot:item.edit_pencil="{ item }">
            <v-icon @click="edit_clicked(item)">mdi-pencil</v-icon>
          </template>
          <template v-slot:item.edited_by="{ item }">
            {{ item.data().edited_by }}
          </template>
          <template v-slot:item.attachment="{ item }">
            <a :href="item.data().attachment_url" target="_blank">
            {{ item.data().attachment }}
          </a>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import selectDepartmentVue from "../components/selectDepartment.vue";
export default {
  name: "Home",
  data() {
    return {
      is_edit: false,
      edit_id: "",
      attachment: "Add attachment",
      faqPair: {
        id : "",
        question: "",
        answer: "",
        department: "",
        attachment: null,
        attachment_url:''
      },
      search: "",
      addedQnaHeaders: [
        { text: "Question", align: "start", value: "question" },
        { text: "Answer", value: "answer" },
        { text: "Department", value: "department" },
        { text: "Username", value: "user" },
        { text: "Date Added", value: "dateTime" },
        { text: "Edit", value: "edit_pencil" },
        { text: "Edited By", value: "edited_by" },
        { text: "Attachment", value: "attachment" },
      ],
      showMyResponse: false,
      courtRules: [(v) => !!v || "Department is required"],
      textRules: [(v) => !!v || "Cannot be empty."],
      selectedDepartment: "",
      errorMessages: "",
    };
  },
  watch: {
    showMyResponse(newVal,items) {
      if (newVal) {
        this.search = this.getUserInfo.name;
      } else {
        this.search = "";
      }
    },
  },
  computed: {
    ...mapGetters(["userProfile", "getFaqs", "getUserInfo"]),
    getDepartments() {
      if (this.userProfile !== null) {
        return this.userProfile.departments;
      } else {
        return ["Something went wrong"];
      }
    },
    // qnaListold() {
    //   let list = [];
    //   this.getFaqs.forEach(item => list.push(item));
    //   return list;
    // },
    // filterShowMyResponse(items){
    //   console.log("inside filtershowmyresponse computed")
    //   this.own_response(items)
    //   return null
    // },
    
    qnaList() {
      let list = [];
      
      this.getFaqs.forEach((item) => {
        
        if ((this.faqPair.question !== "" && this.faqPair.question !== null && this.is_edit === false)) {
          if (item.data().question.toLowerCase().includes(this.faqPair.question.toLowerCase())) {
            list.push(item);
          }
        }
        else {
          list.push(item);
          // print(list, "list in qnaList")
        }
      });
      // console.log(list.filter(li => {
      //   li.question.includes(this.faqPair.question)
      // }),"List here")

      return list;
    },
  },
  methods: {
    // addFaqold() {
    //   if (this.$refs.qna.validate()) {
    //     if (this.faqPair.department !== "") {
    //       this.selectedDepartment = this.faqPair.department;
    //       this.$store.dispatch("addFaq", this.faqPair).then((response) => {
    //         if (response) {
    //           this.$refs.qna.reset();
    //           this.faqPair.department = this.selectedDepartment;
    //         }
    //       });
    //     } else {
    //       this.$refs["depart"].validate(true);
    //     }
    //   }
    // },
    addFaq() {
      if (this.$refs.qna.validate()) {
        if (this.is_edit) {
          // console.log(this.is_edit)

        
          let payload = {
            item_id: this.edit_id,
            name: this.getUserInfo.name,
            faq: this.faqPair,
          };
          // console.log("Updated Values: ")
          // // console.log(this.faq.id,"Id")
          // console.log(this.edit_id)
          // console.log(this.getUserInfo.name)
          console.log(this.faqPair.attachment,"Logging attachmenet here");
          // console.log(this.selectedFile, " Logging selected file")
          
          this.$store.dispatch("updateFaq", payload).then((response) => {
            if (response) {
              this.$refs.qna.reset();
              this.attachment = 'Add attachment'
              // this.faqPair.department = this.selectedDepartment;
            } else {
              this.$refs["depart"].validate(true);
            }
            
          });
          this.is_edit = false
        } else if (this.faqPair.department !== "") {
          this.selectedDepartment = this.faqPair.department;
          this.$store.dispatch("addFaq", this.faqPair).then((response) => {
            if (response) {
              // console.log("Inside resolve")
              this.$refs.qna.reset();
              this.attachment = 'Add attachment'
              this.faqPair.department = this.selectedDepartment;
            }
          });
        } else {
          this.$refs["depart"].validate(true);
        }
        this.qnaList
      }
    },
    edit_clicked(item) {
      this.faqPair.question = item.data().question;
      this.faqPair.answer = item.data().answer;
      if(item.data().attachment){
      this.attachment = item.data().attachment
      this.faqPair.attachment = item.data().attachment.name
      }
      else{
        this.attachment = 'Add attachment'
      }
      this.faqPair.department = item.data().department;
      // this.faqPair.attachment_url = item.data().attachment_url
      
      this.faqPair.id = item.id
      this.is_edit = true;
      this.edit_id = item.id;
      console.log(this.edit_id)
      // console.log('edit clicked')
      // console.log(item.data().question)
      // console.log(item.id)
      
    },

    handleFileChange(event) {
      this.faqPair.attachment = event.target.files[0];
      console.log(this.faqPair.attachment);
      // console.log(selectedFile.name)
      this.attachment = this.faqPair.attachment.name;
      console.log(this.attachment);
      // this.faqPair.attachment = this.attachment;
    },
    resetform() {
      this.$refs.qna.reset();
      this.$refs.file.value = "";
      this.attachment = "Add attachment";
    },
    // own_response(items){
    //   console.log("inside own_response")
    // }
    // filterShowMyResponse(items){
    //   console.log(items , "Items in filter")
    //   let data = []
    //   items.forEach((ele) => {
    //     // console.log("checking user and name ")
    //     // console.log(ele.data().user)
    //     // console.log(this.getUserInfo.name)
    //     if(ele.data().user === this.getUserInfo.name){
    //       data.push(ele)
    //     }
    //     console.log(data,"This is data")
    //     return data
    //   })
    //   // return this.items.filter(item => {
    //   //   item.data().user === this.getUserInfo.name
    //   // })
    // },
    // filterShowMyResponse(item,search){
    //   console.log(this.item)
    //   return item.data().user.includes(this.search)

      // return item.data().user.includes((this.getUserInfo.name))
      // console.log(items,"items in desc")
      // let arr=[]
      // console.log(search, " search ele")
      // return item.forEach((ele) => ele.data().user === this.search)
      // var newones = items.filter(ele => ele.data().user.includes(this.getUserInfo.name));
      // console.log(newones)
      // return newones
      // items.forEach((ele,idx) => {
      //   if(ele.data().user === this.getUserInfo.name){
      //     console.log("here",ele.data())
      //     arr.push(ele.data())
      //   }
      // })
      // return arr
    // }
  },
  filters: {
    trimLength(val) {
      if (!val) {
        return "-";
      }
      if (val.length < 60) {
        return val;
      }
      return `${val.substring(0, 50)}...`;
    },
    formatDate(val) {
      if (!val) {
        return "-";
      }
      let date = val.toDate();
      return moment(date).fromNow();
    },
    
  },
  mounted() {
    //console.log(this.getFaqs)
    //this.$store.commit("setDepartmentDialog", true)
  },
};
</script>
<style>
.selectComponent {
  max-width: 350px;
}
</style>