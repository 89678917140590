import * as fb from '@/firebase/index.js';
import router from '@/router'
export default {
    state: {
        login: false,
        userProfile: null,
        clients: []
    },
    getters: {
        login: state => state.login,
        userProfile: state => state.userProfile,
        getClients: state => state.clients
    },
    mutations: {
        setLogin(state, status) {
            state.login = status
        },
        setUserProfile(state, user) {
            state.userProfile = user
        },
        setClients(state, client) {
            state.clients = client
        }
    },
    actions: {
        login({ dispatch }, userCreds) {
            return new Promise((resolve, reject) => {
                fb.auth.signInWithEmailAndPassword(userCreds.email, userCreds.password).then(cred => {

                    if (window.sessionStorage.getItem('loginEmail') === null) {
                        window.sessionStorage.setItem('loginEmail', userCreds.email);
                    }
                    dispatch("fetchProfile", userCreds.email)
                    resolve('Successfully logged in');
                })
                    .catch(error => {
                        reject(error.message)
                    })
            })
        },
        checkForUser({ commit }) {
            fb.userCollection.get().then(
                response => {
                    if (response.empty) {
                        router.push('/createUser')
                    } else {
                        // call pull data
                    }
                }
            )
        },
        createAccount({ commit, dispatch }, userInfo) {
            return new Promise((resolve, reject) => {
                fb.auth.createUserWithEmailAndPassword(userInfo.email, userInfo.password)
                    .then(cred => {
                        //create user profile in usercollection

                        fb.userCollection.add({
                            name: userInfo.firstName + ' ' + userInfo.lastName,
                            email: userInfo.email,
                            userRole: 'Admin',
                            createdOn: new Date(),
                        }).then(() => {
                            //commit("setLogin", true)  
                        })
                    })
                    .catch(error => {
                        reject(error.message);
                    })
            })
        },
        fetchProfile({ commit, getters, dispatch }, email) {
            fb.userCollection.where('email', '==', email).get().then(response => {
                response.docs.forEach(user => {
                    commit('setUserProfile', user.data())
                    commit("setLogin", true)
                    if (user.data().userRole === 'Admin') {
                        router.push('/adminHome')
                        dispatch('pullClients')
                    } else if (user.data().userRole === 'Client Admin') {
                        router.push('/clientAdminHome')
                        dispatch('pullFaq', user.data().client)
                    } else {
                        router.push('/')
                        commit("setDepartmentDialog", true)
                    }
                })
            })
        },
        createClientAccount({ commit }, client) {
            return new Promise((resolve, reject) => {
                fb.auth.createUserWithEmailAndPassword(client.email, client.password)
                    .then(cred => {
                        //create user profile in usercollection

                        fb.userCollection.add({
                            name: client.name,
                            email: client.email,
                            userRole: 'Client',
                            departments: client.department,
                            createdOn: new Date(),
                        }).then(() => {
                            //commit("setLogin", true)  
                        })
                    })
                    .catch(error => {
                        reject(error.message);
                    })
            })
        },
        createClientAdminAccount({ commit }, client) {
            return new Promise((resolve, reject) => {
                fb.auth.createUserWithEmailAndPassword(client.email, client.password)
                    .then(cred => {
                        //create user profile in usercollection

                        fb.userCollection.add({
                            name: client.name,
                            email: client.email,
                            userRole: 'Client Admin',
                            client: client.client,
                            createdOn: new Date(),
                        }).then(() => {
                            //commit("setLogin", true)  
                        })
                    })
                    .catch(error => {
                        reject(error.message);
                    })
            })
        },
        updateClient({ commit }, client) {
            fb.userCollection.doc(client.id).update({
                name: client.name,
                email: client.email,
                userRole: 'Client',
                departments: client.departments,
            })
        },
        pullClients({ commit }) {
            fb.userCollection.where('userRole', '==', 'Client').onSnapshot(clients => {
                commit('setClients', clients.docs)
            })
        }
    }
}