<template>
    <v-dialog width="35%" persistent v-model="getAddClientDialog">
        <v-card>
            <v-card-title class="justify-center">Add New Client</v-card-title>
            <v-divider></v-divider>
            <v-form class="mx-5" align="center">
                <v-row>
                    <v-col cols="6">
                        <v-text-field label="Client Name" v-model="form.clientName"></v-text-field>
                        <v-text-field label="Client Email" v-model="form.clientEmail"></v-text-field>
                        <v-text-field label="Client Passowrd" v-model="form.clientPassword"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-row no-gutters>
                            <v-col cols="10">
                                <v-text-field label="Add Department" v-model="form.clientDepartment"></v-text-field>
                            </v-col>
                            <v-col cols="2" align-self="center">
                                <v-icon @click="addDepartment(form.clientDepartment)">mdi-plus</v-icon>
                            </v-col>
                            <v-col>
                                <v-sheet min-height="10" max-height="150" class="mb-5 overflow-y-auto"
                                    v-if="departments.length > 0">
                                    <v-chip v-for="(department, i) in departments" :key="i" close class="ma-1"
                                        @click:close="removeDepartment(department)">
                                        {{ department }}
                                    </v-chip>
                                </v-sheet>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <v-btn @click="addClient()" class="ma-3" width="150" outlined
                    color="primary" > Add Client </v-btn>
                <v-btn @click="$store.commit('setAddClientDialog', false)" class="ma-3" width="150" color="primary">
                    Cancel </v-btn>
            </v-form>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: 'addClient',
    data() {
        return {
            form: {
                clientName: '',
                clientEmail: '',
                clientPassword: '',
                clientDepartment: ''
            },
            departments: []
        }
    },
    computed: {
        ...mapGetters(['getAddClientDialog'])
    },
    methods: {
        addDepartment(department) {
            console.log('Added Department', department)
            this.departments.push(department)
            this.form.clientDepartment = ''
        },
        removeDepartment(department){
            console.log(department)
        },
        addClient(){
            this.$store.commit('setAddClientDialog', false)
            let data = {
                name: this.form.clientName,
                email:  this.form.clientEmail,
                password: this.form.clientPassword,
                department: this.departments
            }
            this.$store.dispatch('createClientAccount', data)
        }
    }
}
</script>