<template>
    <v-row no-gutters class="fill-height">
        <v-col cols="12" align-self="center" align="center">
            <v-card flat width="90%" height="750" class="overflow-x-auto">
                <v-card-actions>
                    <v-expansion-panels>
                        <v-expansion-panel v-for="(faq, i) in getFaqs" :key="i">
                            <v-expansion-panel-header> {{ faq.data().question }} </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row no-gutters>
                                    <v-col cols="7" class="text-left">
                                        {{ faq.data().answer }}
                                    </v-col>
                                    <v-divider vertical></v-divider>
                                    <v-col cols="2" align-self="center" align="center">
                                        {{ faq.data().department }}
                                    </v-col>
                                    <v-divider vertical></v-divider>
                                    <v-col cols="2" align-self="center" align="center">
                                        {{ faq.data().user }}
                                    </v-col>
                                    <v-col cols="1" align-self="center" align="center">
                                        <edit-faq :faqIndex="faq"></edit-faq>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
import editFaq from '../components/editFaq.vue'
export default {
    components: { editFaq },
    name: 'clientAdminHome',
    data() {
        return {
            addedQnaHeaders: [
                { text: 'Question', align: 'start', value: 'question' },
                { text: 'Answer', value: 'answer' },
                { text: 'Department', value: 'department' },
                { text: 'Comment', value: 'comment' },
                { text: 'Username', value: 'user' },
                { text: 'Date Added', value: 'dateTime' }
            ],
        }
    },
    computed: {
        ...mapGetters(['getFaqs'])
    }
}
</script>