import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import createUser from '../views/createUser.vue'
import adminHome from '../views/adminHome.vue'
import clientAdminHome from '../views/clientAdminHome.vue'
import login from '../views/login.vue'
Vue.use(VueRouter)
let loginRequired = false
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/createUser',
    name: 'createUser',
    component: createUser
  },
  {
    path: '/adminHome',
    name: 'adminHome',
    component: adminHome
  },
  {
    path: '/login',
    name: 'login',
    component: login,
  },
  {
    path: '/clientAdminHome',
    name: 'clientAdminHome',
    component: clientAdminHome
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  if (window.sessionStorage.getItem('loginEmail') === null && !loginRequired) {

    loginRequired = true
    next('/login')
  } else {
    loginRequired = false
    next()
  }
})
export default router
