<template>
    <v-dialog width="25%" v-model="getLoginDialog" persistent>
        <v-card width="100%">
            <v-card-title class="justify-center">Login</v-card-title>
            <v-divider></v-divider>
            <v-form class="mx-10 my-3">
                <v-text-field label="User Name" v-model="logInForm.email"></v-text-field>
                <v-text-field label="Password" v-model="logInForm.password"></v-text-field>
            </v-form>
            <v-card-actions class="justify-center">
                <v-btn color="primary" width="125" outlined @click="verifyLogin()"> <v-icon left>mdi-login</v-icon> Login </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: "login",
    data(){
        return{ 
            logInForm: {
                email: '',
                password: ''
            }
        }
    },
    computed: {
        ...mapGetters(["getLoginDialog"])
    },
    methods:{
        verifyLogin(){
            this.$store.dispatch('login', this.logInForm)
        }
    }
}
</script>