<template>
  <v-dialog
          @click:outside="$store.commit('showHelpDialog', false)"
          v-model="getHelpDialog"
          :fullscreen="$vuetify.breakpoint.xsOnly"
          width="60%">
    <v-card class="px-5">
        <div style="position: absolute; align-self: end; right: 0">
            <v-btn
                    @click="$store.commit('showHelpDialog', false)"
                    icon
                    class="ma-1" >
                <v-icon color="red" >mdi-window-close</v-icon>
            </v-btn>
        </div>
        <v-card-title class="d-flex justify-center ">
            <span class="text-h5 font-weight-medium">
                Here’s how you can help Clara
            </span>
        </v-card-title>
        <v-divider></v-divider>
      <v-card-text class="my-2">
          <ul>
              <li>
                  <span>
                      In the <strong>QUESTION</strong> box, enter a question that you think a person entering your court would ask. <br>
                      <strong> For example: <i> “Where is the Jury Assembly room? Do I have hearing today?  Where is my court hearing?  How do I file for Divorce? Etc.” </i></strong>
                  </span>
              </li>
              <li>
                  <span>
                      In the <strong> ANSWER </strong> box, enter the answer. <br>
                      If it’s a map WayFinding answer, enter something like: <strong> <i> “Here is a map showing you how to get there.” </i> </strong> <br>
                      <ul>
                          <li>
                              <span>We’ll put in the appropriate map.</span>
                          </li>
                      </ul>
                  </span>
              </li>
              <li>
                  <span>
                      If there is any <strong> COMMENTS </strong> to your Question & Answer submissions, please put them in the COMMENTS box.
                  </span>
              </li>
              <li>
                  <span>
                      <strong> Enter as many Questions and Answers as possible.</strong> There are no stupid questions, the more, the better.
                  </span>
              </li>
              <li>
                  <span>
                      If there are different ways a person might ask a Question, please put in another Question & Answer. <strong> Let Clara know all the different ways a person might ask the same question. </strong>
                  </span>
              </li>
              <li>
                  <span>
                      If you have any questions, or <strong> need any help,
                      <a target="_blank" href="mailto:shubham@robotaisolutions.com?cc=pritesh@robotaisolutions.com&subject=Clara%20Orientation%20Feedback">
                          Please Click Here To Send Email
                      </a></strong>
                  </span>
              </li>
          </ul>
          <div class="d-flex justify-end">
              <v-btn
                      @click="$store.commit('showHelpDialog', false)"
                      color="primary"
                      elevation="0"
                      tile>
                  Continue
              </v-btn>
          </div>
      </v-card-text>
        <v-divider></v-divider>
        <v-card-text align="center" class="mt-2">
            <span class="text-lg-h6">
                THANK YOU FOR YOUR HELP!
            </span>
        </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: "login",
  computed: {
    ...mapGetters(['getHelpDialog'])
  },
}
</script>

<style scoped>

</style>
