<template>
  <v-dialog width="35%" persistent v-model="show">
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        v-bind="attrs"
        v-on="on"
        class="mr-2"
        @click="editUser"
        color="primary"
      >
        mdi-information
      </v-icon>
    </template>
    <v-card>
      <v-card-title class="justify-center">Email</v-card-title>
      <v-divider></v-divider>
      <v-form class="mx-5" align="center">
        <v-row>
          <v-col cols="9">
            <v-text-field
              label="Client Email"
              v-model="client.email"
              disabled
            ></v-text-field>
          </v-col>

          <v-col cols="3" class="mt-4">
            <!-- <v-tooltip>
                    <v-icon @click="copyEmail">mdi-content-copy</v-icon>
                </v-tooltip> -->

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  @click="copyEmail"
                >
                  mdi-content-copy
                </v-icon>
              </template>
              <span>Copy Email</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-btn @click="show = false" class="ma-3" width="150" color="primary">
          Cancel
        </v-btn>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "emailPopup",
  props: {
    clientIndex: Object,
  },
  mounted() {},
  data() {
    return {
      show: false,
      client: {
        name: "",
        email: "",
        departments: [],
        id: null,
      },
      department: "",
    };
  },
  methods: {
    editUser() {
      this.client = Object.assign({}, this.clientIndex.data());
      this.client.id = this.clientIndex.id;
    },
    copyEmail() {
      console.log("clicked copy");
      console.log(this.client.email, "copied email");
      // Create a new input element to hold the email ID
      navigator.clipboard.writeText(this.client.email);
    },
  },
};
</script>