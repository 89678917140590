import * as fb from '@/firebase/index.js';
export default {
    state: {
        helpDialog: false,
        loginDialog: false,
        snackBar: {},
        addClientDialog: false,
        addClientAdminDialog: false,
        departmentDialog: false
    },
    getters: {
        getHelpDialog: state => state.helpDialog,
        getLoginDialog: state => state.loginDialog,
        snackBar: state => state.snackBar,
        getAddClientDialog: state => state.addClientDialog,
        getClientAdminDialog: state => state.addClientAdminDialog,
        getDepartmentDialog: state => state.departmentDialog
    },
    mutations: {
        showHelpDialog(state, status) {
            state.helpDialog = status;
        },
        showLoginDialog(state, status) {
            console.log('Coming to the mutation')
            state.loginDialog = status
        },
        setSnackBar(state, snackBar) {
            state.snackBar = snackBar;
        },
        setAddClientDialog(state, status){
            state.addClientDialog = status
        },
        setDepartmentDialog(state, status){
            state.departmentDialog = status
        },
        setClientAdminDialog(state, status){
            state.addClientAdminDialog = status
        }
    },
    actions: {

    }
}