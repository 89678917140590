import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import {auth} from './firebase/index'
import VueClipboard from 'vue-clipboard2'

Vue.config.productionTip = false
let app;

auth.onAuthStateChanged(user =>{
  if(!app){
    app = new Vue({
      router,
      store,
      vuetify,
      VueClipboard,
      render: h => h(App)
    }).$mount('#app')
  }
  if(user){
    //console.log('At main js file: ' ,user.email)
    //store.dispatch("fetchProfile", user.email)
  }
})
