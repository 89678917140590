<template>
    <v-dialog width="45%" v-model="show" persistent>
        <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" @click="editFaq" color="primary">
                mdi-pencil
            </v-icon>
        </template>
        <v-card>
            <v-card-title class="justify-center"> Edit Faqs </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="6" align-self="center">
                        <v-textarea :value="faqIndex.data().question" label="Question" v-model="faqs.question"></v-textarea>
                    </v-col>
                    <v-col cols="6" align-self="center">
                        <v-textarea :value="faqIndex.data().answer" label="Answer" v-model="faqs.answer"></v-textarea>
                    </v-col>
                    <v-col cols="6" align-self="center">
                        <span class="black--text"> Department: {{ faqs.department }}</span>
                    </v-col>
                    <v-col cols="6" align-self="center">
                        <span class="black--text"> Added By: {{ faqs.user }}</span>
                    </v-col>
                </v-row>
                
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn width="175" color="primary" @click="updateFaq()">Edit</v-btn>
                <v-btn width="175" @click="show = false" outlined color="primary">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    name: 'editFaq',
    props: {
        faqIndex: Object
    },
    data(){
        return{
            show: false,
            faqs:{
                question: '',
                answer: '',
                department: '',
                user: '',
                id: null                
            }
        }
    },
    methods:{
        editFaq(){
            this.faqs = Object.assign({}, this.faqIndex.data());
            this.faqs.id = this.faqIndex.id
        },
        updateFaq(){
            this.show = false
            this.$store.dispatch('updateFaq', this.faqs)
        }
    }
}
</script>