import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'

Vue.use(Vuex);

const store = new Vuex.Store({
  modules,
});


export default store;



// import Vue from 'vue'
// import Vuex from 'vuex'
// import * as fb from '@/firebase/index.js';

// Vue.use(Vuex)

// export default new Vuex.Store({
//   state: {
//     loginDialog: false,
//     openDialog: false,
//     helpDialog: false,
//     qnaList: [],
//     allQna: [],
//     snackBar:{},
//     userInfo: {
//       name: '',
//       courtHouse: ''
//     }
//   },
//   mutations: {
//     openLoginDialog: (state, status) => {
//       state.loginDialog = status
//     },

//     updateOpenDialog: (state, openDialog) => {
//       state.openDialog = openDialog
//     },
//     updateQnaList: (state, qnaList) => {
//       state.qnaList = qnaList
//     },
//     updateAllQnaList: (state, qnaList) => {
//       state.allQna = qnaList
//     },
//     updateUserInfo: (state, userInfo) => {
//       state.userInfo = userInfo
//     },
//     showHelpDialog(state, show){
//       state.helpDialog = show;
//     },
//     setSnackBar(state, snackBar){
//       state.snackBar = snackBar;
//     },
//   },
//   actions: {
//     login({ commit }, form) {
//       return new Promise((resolve, reject) => {
//         fb.auth.signInWithEmailAndPassword(form.email, form.password).then(cred => {
//           console.log(`user logged In: ${cred.user.uid}`);
//           //fetch user profile
//           //dispatch('fetchUserProfile', cred.user);
//           commit('openLoginDialog', false)
//           commit('updateOpenDialog', true)
//           resolve('Successfully Logged In');
//         })
//           .catch(error => {
//             reject(error.message)
//             //Todo: add logging mechanism to be stored on firebase for each account
//           })
//       })
//     },




//     getAllQnA({commit, getters}) {
//       return new Promise((resolve) => {        
//         fb.qnaCollection.where("courtName", "==", getters.getUserInfo.courtHouse).onSnapshot(querySnapshot => {
//           commit('updateQnaList', querySnapshot.docs)
//             console.log('Data Fetched')
//             resolve('Data Fetched successfully')
//         })
//       })
//     },
//     getQnA({commit}) {
//       return new Promise((resolve) => {        
//         fb.qnaCollection.onSnapshot(querySnapshot => {
//           commit('updateAllQnaList', querySnapshot.docs)
//             console.log('Data Fetched')
//             resolve('Data Fetched successfully')
//         })
//       })
//     },
//     showSnackBar({commit}, snackBar){
//       snackBar.show = true;
//       snackBar.color = (snackBar.color === undefined) ? 'grey darken-2' : snackBar.color;
//       snackBar.timeout = (snackBar.timeout === undefined) ? 5000 : snackBar.timeout ;
//       snackBar.icon = (snackBar.icon === undefined) ? 'mdi-information-outline' : snackBar.icon;
//       commit('setSnackBar', snackBar);
//     },
//   },
//   modules: {
//   },
//   getters:{
//     getOpenDialog: state => state.openDialog,
//     getUserInfo: state => state.userInfo,
//     getQnaList: state => state.qnaList,
//     getAllQna: state => state.allQna,
//     helpDialog: state => state.helpDialog,
//     snackBar: state => state.snackBar,
//     getLoginDialog: state => state.loginDialog
//   },
// })
