<template>
    <v-dialog width="35%" persistent v-model="getClientAdminDialog">
        <v-card>
            <v-card-title class="justify-center">Add New Client Admin</v-card-title>
            <v-divider></v-divider>
            <v-form class="mx-5" align="center">
                <v-row>
                    <v-col cols="6">
                        <v-text-field label="Client Name" v-model="form.clientName"></v-text-field>
                        <v-text-field label="Client Email" v-model="form.clientEmail"></v-text-field>
                        <v-text-field label="Client Passowrd" v-model="form.clientPassword"></v-text-field>
                        <v-select v-model="form.client" :items="getCourts" label="Select Client"></v-select>
                    </v-col>
                </v-row>

                <v-btn @click="addClientAdmin()" class="ma-3" width="175" outlined color="primary"> Add Client Admin </v-btn>
                <v-btn @click="$store.commit('setClientAdminDialog', false)" class="ma-3" width="175" color="primary">
                    Cancel </v-btn>
            </v-form>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: 'addClientAdmin',
    data() {
        return {
            form: {
                clientName: '',
                clientEmail: '',
                clientPassword: '',
                client: ''
            },
        }
    },
    computed: {
        ...mapGetters(['getClientAdminDialog', 'getClients']),
        getCourts() {
            let temp = []
            this.getClients.forEach(client => {
                temp.push(client.data().name)
            })
            return temp
        }
    },
    methods: {
        addClientAdmin() {
            this.$store.commit('setClientAdminDialog', false)
            let data = {
                name: this.form.clientName,
                email: this.form.clientEmail,
                password: this.form.clientPassword,
                client: this.form.client
            }
            this.$store.dispatch('createClientAdminAccount', data)
        }
    }
}
</script>