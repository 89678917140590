import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/database'
import "firebase/storage";
// import 'firebase/firestore'
// import {getStorage} from "firebase/Storage"

let firebaseConfig = {
    apiKey: "AIzaSyBxpVFV6btWG398NXl1lf77rHurAhw-YWs",
    authDomain: "questionnaire-57679.firebaseapp.com",
    projectId: "questionnaire-57679",
    storageBucket: "questionnaire-57679.appspot.com",
    messagingSenderId: "652414090068",
    appId: "1:652414090068:web:de6da97f6bb660d5d9a6c1",
    measurementId: "G-TM7R772ZV1"
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();
// const storage = firebase.firestore();
const qnaCollection = db.collection('qnaPair');
const userCollection = db.collection('users');
const clientCollection = db.collection('clients');
const faqCollection = db.collection('faqs')
// const storage = firebase.storage().ref()
const storage = firebase.storage()
export {
    db, 
    auth,
    firebase,
    qnaCollection,
    userCollection,
    clientCollection,
    faqCollection,
    storage
    // storage
} 