<template>
    <v-row class="fill-height">
        <v-col align-self="center" align="center"> 
            <v-card width="400">
                <v-card-title>Create Login</v-card-title>
                <v-form class="ma-5">
                    <v-text-field label="First Name" v-model="form.firstName"></v-text-field>
                    <v-text-field label="Last Name" v-model="form.lastName"></v-text-field>
                    <v-text-field label="Login Email" v-model="form.email"></v-text-field>
                    <v-text-field label="Password" v-model="form.password"></v-text-field>
                    <v-btn class="my-4" @click="createAdmin()"> Create Admin</v-btn>
                </v-form>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'createUser',
    data(){
        return{
            form:{
                firstName: '',
                lastName: '',
                email: '',
                password: ''
            }
        }
    },
    methods:{
        createAdmin(){
            this.$store.dispatch('createAccount', this.form)
        }
    }
}
</script>