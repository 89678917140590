import * as fb from "@/firebase/index.js";
export default {
  state: {
    userInfo: {},
    faqs: [],
    getIdCount: null,
  },
  getters: {
    getUserInfo: (state) => state.userInfo,
    getFaqs: (state) => state.faqs,
  },
  mutations: {
    updateUserInfo(state, info) {
      state.userInfo = info;
    },
    setFaqs(state, faq) {
      state.faqs.push(faq);
    },
    emptyFaqs(state) {
      state.faqs = [];
    },
    removeFaq(state, id) {
      let idx = state.faqs.indexOf(id);
      state.faqs.splice(idx, 1);
    },
  },
  actions: {
    // addFaqold({ getters }, qnaPair) {
    //   let storageRef = fb.storage.ref();
    //   var uploadTask = storageRef
    //     .child("images/" + qnaPair.attachment.name)
    //     .put(qnaPair.attachment);
    //   return new Promise((resolve, reject) => {
    //     fb.faqCollection
    //       .add({
    //         question: qnaPair.question,
    //         answer: qnaPair.answer,

    //         courtName: getters.userProfile.name,
    //         department: qnaPair.department,
    //         user: getters.getUserInfo.name,
    //         dateTime: new Date(),
    //         attachment: qnaPair.attachment.name,
    //       })
    //       .then(() => {
    //         resolve(true);
    //       })
    //       .catch((error) => {
    //         reject(false);
    //       });
    //   });
    // },

    addFaq({ getters }, qnaPair) {
      //   console.log(qnaPair);
      //   let metadata = {
      //     contentType: qnaPair.attachment.type,
      //   };

      if (qnaPair.attachment != null) {
        return new Promise((resolve, reject) => {
          let storageRef = fb.storage.ref();
          let uploadTask = storageRef
            .child(getters.userProfile.name + "/" + qnaPair.attachment.name)
            .put(qnaPair.attachment);
          uploadTask
            .then((snapshot) => {
              return snapshot.ref.getDownloadURL();
            })
            .then((downloadURL) => {
              qnaPair.attachment_url = downloadURL;
              return fb.faqCollection.get();
            })
            .then((querySnapshot) => {
              let maxLinkId = 0;
              querySnapshot.forEach((doc) => {
                const linkId = doc.data().linkId;

                if (linkId > maxLinkId) {
                  maxLinkId = linkId;
                }
              });

              // const getCountID = maxLinkId + 1;
              // console.log(getCountID,"get the count of docs")
              return fb.faqCollection.add({
                linkId: maxLinkId + 1,
                question: qnaPair.question,
                answer: qnaPair.answer,
                courtName: getters.userProfile.name,
                department: qnaPair.department,
                user: getters.getUserInfo.name,
                dateTime: new Date(),
                attachment: qnaPair.attachment.name,
                attachment_url: qnaPair.attachment_url,
              });
            })
            .then(() => {
              resolve(true); // Resolve the promise once the operation is complete
            })
            .catch(() => {
              reject(false); // Reject the promise if there is an error
            });
        });
      } else {
        return new Promise((resolve, reject) => {
          fb.faqCollection.get()
            .then((querySnapshot) => {
              let maxLinkId = 0;
              querySnapshot.forEach((doc) => {
                const linkId = doc.data().linkId;
                if (linkId > maxLinkId) {
                  maxLinkId = linkId;
                }
              });
              return maxLinkId;
            })
            .then((maxLinkId) => {
              return fb.faqCollection.add({
                linkId: maxLinkId + 1,
                question: qnaPair.question,
                answer: qnaPair.answer,
                courtName: getters.userProfile.name,
                department: qnaPair.department,
                user: getters.getUserInfo.name,
                dateTime: new Date(),
              });
            })
            .then(() => {
              resolve(true);
            })
            .catch(() => {
              reject(false);
            });
        });
      }

      //   let storageRef = fb.storage.ref();
      //   storageRef.child("images/" + qnaPair.attachment.name)
      //     .put(qnaPair.attachment)
      //     .then((resolve, reject) => {
      //       resolve.ref
      //         .getDownloadURL()
      //         .then((downloadURL) => {
      //           qnaPair.attachment_url = downloadURL;
      //           fb.faqCollection.add({
      //             question: qnaPair.question,
      //             answer: qnaPair.answer,

      //             courtName: getters.userProfile.name,
      //             department: qnaPair.department,
      //             user: getters.getUserInfo.name,
      //             dateTime: new Date(),
      //             attachment: qnaPair.attachment.name,
      //             attachment_url: qnaPair.attachment_url,
      //           })
      //           .then(()=> {
      //             resolve(true)
      //           })
      //           .catch(()=> {
      //             reject(false)
      //           })
      //         })

      //     });

      // .then((resolve,reject) => {
      //     console.log(qnaPair,"inside last then")
      //     console.log(resolve,"Resolve in last one ")
      //     fb.faqCollection
      //       .add({
      //         question: qnaPair.question,
      //         answer: qnaPair.answer,
      //         courtName: getters.userProfile.name,
      //         department: qnaPair.department,
      //         user: getters.getUserInfo.name,
      //         dateTime: new Date(),
      //         attachment: qnaPair.attachment.name,
      //         attachment_url: qnaPair.attachment_url
      //       })
      //     //   console.log(qnaPair, 'inside all')
      //       .then(() => {
      //         resolve(true);
      //       })
      //       .catch(() => {
      //         reject(false);
      //       });
      // })

      // return new Promise((resolve, reject) => {
      //     console.log(qnaPair.attachment_url,'this is new Promise url')
      //     console.log(resolve," this is resolve in Promise")
      //     fb.faqCollection
      //       .add({
      //         question: qnaPair.question,
      //         answer: qnaPair.answer,

      //         courtName: getters.userProfile.name,
      //         department: qnaPair.department,
      //         user: getters.getUserInfo.name,
      //         dateTime: new Date(),
      //         attachment: qnaPair.attachment.name,
      //         attachment_url: qnaPair.attachment_url
      //       })
      //       .then(() => {
      //         resolve(true);
      //       })
      //       .catch((error) => {
      //         reject(false);
      //         console.log(error)
      //       });
      //   });
    },
    // addFaqoldest({ getters }, qnaPair) {
    //   return new Promise((resolve, reject) => {
    //     fb.faqCollection
    //       .add({
    //         question: qnaPair.question,
    //         answer: qnaPair.answer,

    //         courtName: getters.userProfile.name,
    //         department: qnaPair.department,
    //         user: getters.getUserInfo.name,
    //         dateTime: new Date(),
    //         attachment: qnaPair.attachment.name,
    //       })
    //       .then(() => {
    //         resolve(true);
    //       })
    //       .catch((error) => {
    //         reject(false);
    //       });
    //   });
    // },

    pullFaq({ getters, commit }, courtName) {
      fb.faqCollection
        .where("courtName", "==", courtName)
        .onSnapshot((faqs) => {
          faqs.docChanges().forEach((change) => {
            if (change.type === "added") {
              commit("setFaqs", change.doc);
            } else if (change.type === "modified") {
              getters.getFaqs.forEach((ele, idx) => {
                // console.log(ele.id,ele.question,change.doc.id,"Printing inside")
                if (ele.id === change.doc.id) {
                  // console.log(ele.data().question,ele.data().answer)
                  getters.getFaqs.splice(idx, 1);
                }
              });
              // let idx = getters.getFaqs.indexOf(change.doc.id)
              // console.log(idx,"Index here")
              // console.log(change.doc.data().question,"Qustion here")

              commit("setFaqs", change.doc);

              // getters.getFaqs.splice(edit_id,1)
              // console.log(edit_id," Edit ID printed")
            }
          });
        });
    },
    // updateFaq({getters}, faq){
    //     fb.faqCollection.doc(faq.id).update({
    //         question: faq.question,
    //         answer: faq.answer,

    //         updated: true
    //     })
    // }
    // updateFaq({getters}, faq,item_id,edited_by){
    //     fb.faqCollection.doc(item_id).update({
    //         department:faq.department,
    //         question: faq.question,
    //         answer: faq.answer,
    //         comment : faq.department,
    //         edited_by : edited_by,
    //         updated: true
    //     })
    // },
    updateFaq({ getters }, payload) {
      console.log(payload, "payload values");
      // console.log(this.payload)
      // console.log(payload.item_id,payload.name,"Values")
      if (payload.faq.attachment != null) {
        return new Promise((resolve, reject) => {
          console.log(
            payload.faq.attachment,
            "updating attachment when nothing attached"
          );
          let storageRef = fb.storage.ref();
          let uploadTask = storageRef
            .child(getters.userProfile.name + "/" + payload.faq.attachment.name)
            .put(payload.faq.attachment);
          uploadTask
            .then((snapshot) => {
              return snapshot.ref.getDownloadURL();
            })
            .then((downloadURL) => {
              payload.faq.attachment_url = downloadURL;
              // console.log(payload,"payload before firebase update")
              fb.faqCollection.doc(payload.item_id).update({
                // fb.collection("faqs").doc(payload.item_id)({
                question: payload.faq.question,
                answer: payload.faq.answer,

                // courtName: getters.userProfile.name,
                department: payload.faq.department,
                // user: getters.getUserInfo.name,
                // dateTime: new Date(),
                edited_by: payload.name,
                updated: true,
                attachment: payload.faq.attachment.name,
                attachment_url: payload.faq.attachment_url,
              });
            })
            .then(() => {
              resolve(true); // Resolve the promise once the operation is complete
            })
            .catch(() => {
              reject(false); // Reject the promise if there is an error
            });
        });
      } else {
        console.log(payload, "payload without attachment");
        return new Promise((resolve, reject) => {
          fb.faqCollection
            .doc(payload.item_id)
            .update({
              question: payload.faq.question,
              answer: payload.faq.answer,

              // courtName: getters.userProfile.name,
              department: payload.faq.department,
              // user: getters.getUserInfo.name,
              // dateTime: new Date(),
              edited_by: payload.name,
              updated: true,
              //   attachment: payload.faq.attachment,
              //   attachment_url: payload.faq.attachment_url,
            })
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              reject(false);
            });
        });
      }

      //   return new Promise((resolve, reject) => {
      //     fb.faqCollection
      //       .doc(payload.item_id)
      //       .update({
      //         question: payload.faq.question,
      //         answer: payload.faq.answer,

      //         // courtName: getters.userProfile.name,
      //         department: payload.faq.department,
      //         // user: getters.getUserInfo.name,
      //         // dateTime: new Date(),
      //         edited_by: payload.name,
      //         updated: true,
      //         attachment: payload.faq.attachment,
      //         attachment_url: payload.faq.attachment_url,
      //       })
      //       .then(() => {
      //         resolve(true);
      //       })
      //       .catch((error) => {
      //         reject(false);
      //       });
      //   });
    },
  },
};
