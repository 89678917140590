<template>
  <v-row no-gutters class="fill-height">
    <v-col align-self="center" align="center" cols="12">
      <v-btn
        width="175"
        height="50"
        outlined
        class="ma-2"
        @click="$router.push('/createUser')"
      >
        Add Admin
      </v-btn>
      <v-btn
        width="175"
        height="50"
        outlined
        class="ma-2"
        @click="$store.commit('setClientAdminDialog', true)"
      >
        Add Client Admin
      </v-btn>
      <v-btn
        width="175"
        height="50"
        outlined
        class="ma-2"
        @click="$store.commit('setAddClientDialog', true)"
      >
        Add Client
      </v-btn>
    </v-col>
    <v-col cols="2">
      <v-col>
        <v-container>
          <v-card
            color="primary"
            class="d-flex align-center justify-center white--text"
          >
            <v-card-title>Clients</v-card-title>
          </v-card>

          <div class="scrollable-card">
            <v-card
              align-self="center"
              v-for="(client, i) in getClients"
              :key="i"
              cols="12"
              class="mt-1 black--text"
            >
              <v-card-title class="text-subtitle-1"
                >{{ client.data().name }}
              </v-card-title>

              <v-card-subtitle class="caption"
                >{{ client.data().email }}
              </v-card-subtitle>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <edit-client :clientIndex="client"></edit-client>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="pullFaqs(client.data().name)"
                      icon
                      v-bind="attrs"
                      v-on="on"
                      class="mx-3"
                    >
                      <v-icon color="primary">mdi-eye-circle</v-icon>
                    </v-btn>
                  </template>
                  <span>View FAQ's</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <!-- <v-icon color="black">mdi-information</v-icon> -->
                      <emailPopup :clientIndex="client"></emailPopup>
                    </v-btn>
                  </template>
                  <span>Email Info</span>
                </v-tooltip>
              </v-card-actions>
            </v-card>
          </div>
        </v-container>
      </v-col>
      <!-- <v-card width="85%" :height="height" flat>
                <v-card-title class="justify-center"> Clients </v-card-title>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-row>
                        <v-col align-self="center" v-for="(client, i) in getClients" :key="i" cols="12">
                            <v-card height="50" width="90%" color="primary">
                                <v-card-title class="pa-2">
                                    <v-row no-gutters>
                                        <v-col cols="2">
                                            <v-btn @click="pullFaqs(client.data().name)"> Select</v-btn>
                                        </v-col>
                                        <v-col cols="4" class="white--text" align="center"> {{ client.data().name
                                        }}
                                        </v-col>
                                        <v-col cols="4" class="white--text" align="left"> {{ client.data().email
                                        }}
                                        </v-col>
                                        <v-col cols="2" align="right">
                                            <edit-client :clientIndex="client"></edit-client>
                                        </v-col>
                                    </v-row>
                                </v-card-title>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card> -->
    </v-col>
    <v-col cols="10" align="center">
      <v-card width="85%" :height="height" flat>
        <v-card-title class="justify-center">
          Frequently Asked Questions
        </v-card-title>
        <v-divider></v-divider>
        <v-card-title class="justify-center" v-if="getFaqs.length !== 0"
          >FAQs Added: {{ getFaqs.length }}</v-card-title
        >
        <v-card
          v-if="getFaqs.length !== 0"
          class="overflow-y-auto ma-5"
          :height="height - 175"
          flat
        >
          <v-expansion-panels class="px-6" focusable dark>
            <v-expansion-panel
              v-for="(item, index) in getFaqs"
              class="mt-2"
              :key="index"
            >
              <v-expansion-panel-header color="primary" class="white--text">
                {{ item.data().question }}
              </v-expansion-panel-header>
              <v-expansion-panel-content
                class="black--text px-2 pt-2 text-justify"
                color="white"
              >
                <v-row class="fill-height" no-gutters>
                  <v-col cols="12">
                    <span>Answer: {{ item.data().answer }}</span>
                    <br />
                    <span>Department: {{ item.data().department }}</span>
                    <br />
                    <span> User: {{ item.data().user }}</span>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
        <v-row no-gutters v-else-if="!select">
          <v-col cols="12" align-self="center" align="center" class="mt-10">
            <span class="text-h6">
              Please Select a Court to see added FAQS</span
            >
          </v-col>
        </v-row>
        <v-row no-gutters v-else>
          <v-col cols="12" align-self="center" align="center" class="mt-10">
            <span class="text-h6"> No Question Added </span>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
import editClient from "../components/editClient.vue";
import emailPopup from "../components/emailPopup.vue";
export default {
  components: { editClient, emailPopup },
  name: "adminHome",
  data() {
    return {
      select: false,
    };
  },
  computed: {
    height() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 350;
        case "lg":
          return 450;
        case "xl":
          return 600;
        default:
          return 500;
      }
    },
    ...mapGetters(["getClients", "getFaqs"]),
  },
  methods: {
    pullFaqs(client) {
      this.select = true;
      this.$store.commit("emptyFaqs");
      this.$store.dispatch("pullFaq", client);
    },
  },
};
</script >

<style scoped>
.scrollable-card {
  max-height: 500px; /* Adjust the desired maximum height */
  overflow-y: auto;
}
</style>
