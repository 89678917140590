<template>
    <v-row class="fill-height">
        <v-col cols="12" align-self="center">
            <v-card flat align="center">
                <v-img src="https://arsconnect.com/wp-content/uploads/2021/02/home-page.png" :height="height"
                    :width="width">
                    <v-row class="fill-height">
                        <v-col cols="12" align-self="center">
                            <v-card width="25%">
                                <v-card-title class="justify-center">Login</v-card-title>
                                <v-divider></v-divider>
                                <v-form class="mx-5" lazy-validation ref="logInForm">
                                    <span class="red--text">{{ loginMessage }}</span>
                                    <v-text-field label="Email" v-model="logInForm.email" :rules="rules.emailRules">
                                    </v-text-field>
                                    <v-text-field label="Password" v-model="logInForm.password"
                                        :rules="rules.passwordRule" :type="passwordFieldType"
                                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                        @click:append="toggleshowPassword"></v-text-field>
                                    <v-btn @click="verifyLogin()" width="125" class="my-5" outlined color="primary">
                                        Login
                                        <v-icon right color="primary">mdi-login</v-icon>
                                    </v-btn>
                                </v-form>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-img>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
export default {
    name: 'login',
    data() {
        return {
            logInForm: {
                email: '',
                password: ''
            },
            passwordFieldType: 'password',
            showPassword: false,
            showPasswordReset: false,
            rules: {
                emailRules: [
                    v => !!v || 'E-mail is required',
                    v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                ],
                passwordRule: [
                    v => !!v || 'Password is required',
                    v => (v && v.length >= 8) || 'Password must be 8 characters long',
                ],
            },
            height: window.innerHeight,
            width: window.innerWidth,
            loginMessage: ''
        }
    },
    methods: {
        verifyLogin() {
            if (this.$refs.logInForm.validate()) {
                this.$store.dispatch('login', this.logInForm).then(response => {
                    this.loginMessage = response
                }, error => {
                    this.loginMessage = 'Invalid Login Credentials'
                }
                )
            }
        },
        toggleshowPassword() {
            this.showPassword = !this.showPassword;
            this.passwordFieldType = (this.showPassword) ? 'text' : 'password';
        }
    }
}
</script>